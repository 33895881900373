import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import localeEs from '@angular/common/locales/es';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PantallaCompletaComponent} from './infrastructure/layout/pantalla-completa/pantalla-completa.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';
import {MsalRedirectComponent} from '@azure/msal-angular';
import {PantallaConMenuComponent} from './infrastructure/layout/pantalla-con-menu/pantalla-con-menu.component';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {provideToastr, ToastrModule} from 'ngx-toastr';
import {ValidationService} from './infrastructure/services/validation.service';
import {ConversacionesModule} from './application/shared/modules/conversaciones/conversaciones.module';
import {CLIPBOARD_OPTIONS, ClipboardButtonComponent, MarkdownModule, MARKED_OPTIONS,} from 'ngx-markdown';
import * as Sentry from "@sentry/angular";
import {Router} from '@angular/router';
import {
  ConfiguracionMsalModule
} from './application/public/dynamic-configurations/configuracion-msal/configuracion-msal.module';
import {
  ImagenCorporativaModule
} from './application/public/dynamic-configurations/imagen-corporativa/imagen-corporativa.module';
import {ForzarRedireccionService} from './infrastructure/services/forzar-redireccion.service';
import {registerLocaleData} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GrupoBotonesModule} from "./application/shared/modules/grupo-botones/grupo-botones.module";
import {LoadingButtonDirective} from "./infrastructure/directives/loading-button.directive";
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {ModalModule} from './application/shared/modules/modal/modal.module';
import {PanelLateralModule} from './application/shared/modules/panel-lateral/panel-lateral.module';
import {AyudaModule} from './domain/shared/ayuda/ayuda.module';
import {TooltipModule} from "./application/shared/modules/tooltip/tooltip.module";
import {GlobalErrorHandler} from "./infrastructure/global-error-handler";
import {AngularxDatatableModule} from "@lunia/angularx-datatable";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.apiUrl + '/public/langs/', '');
}

registerLocaleData(localeEs);
const serverErrorsRegex = new RegExp(
  `500 Internal Server Error|401 Unauthorized|403 Forbidden|404 Not Found|502 Bad Gateway|503 Service Unavailable|401 OK`,
  'mi'
);
Sentry.init({
  dsn: environment.sentryDsn,
  release: 'luniagpt-frontend@latest',
  integrations: [
  ],
  ignoreErrors: [serverErrorsRegex],
  tracesSampleRate: 0.0,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.0,
});

@NgModule({
  declarations: [
    AppComponent,
    PantallaCompletaComponent,
    PantallaConMenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ConfiguracionMsalModule.forRoot(),
    ImagenCorporativaModule.forRoot(),
    NgxUiLoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    ConversacionesModule,
    MarkdownModule.forRoot({
        clipboardOptions: {
          provide: CLIPBOARD_OPTIONS,
          useValue: {
            buttonComponent: ClipboardButtonComponent,
          },
        },
        markedOptions: {
          provide: MARKED_OPTIONS,
          useValue: {
            gfm: true,
            breaks: true,
          },
        },
      }
    ),
    FormsModule,
    GrupoBotonesModule,
    LoadingButtonDirective,
    ReactiveFormsModule,
    AngularxDatatableModule,
    ModalModule,
    PanelLateralModule,
    AyudaModule,
    TooltipModule
  ],
  providers: [
    ValidationService,
    ForzarRedireccionService,
    provideAnimations(),
    provideToastr(),
    {
      provide: ErrorHandler,
      useValue: GlobalErrorHandler
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-ES'
    }
  ],
  exports: [],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
