<p *ngIf="carpetas.length" class="text-slate-500 text-sm select-none">{{ 'CARPETAS' | translate }}</p>
<li *ngFor="let carpeta of carpetas">
  <div
    class="justify-between text-indigo-600 group gap-x-3 mt-3 text-sm leading-6 font-semibold c-pointer conversacion"
    [ngClass]="{'bg-indigo-100 rounded-md': carpeta.dragOn}"
    (dragover)="onDragOver($event, carpeta)"
    (drop)="onDrop($event, carpeta)"
    (dragleave)="onDragLeave($event, carpeta)">
    <div class="justify-between flex gap-x-2 p-2 hover:bg-indigo-100 rounded-md carpeta"
         (click)="toggleCarpeta(carpeta)">
      <div class="flex justify-between gap-x-2">
        @if (carpeta.abierta) {
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776"/>
          </svg>
        } @else if (carpeta.dragOn) {
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"/>
          </svg>

        } @else {
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"/>
          </svg>
        }
        <span class="truncate max-w-menu select-none">{{ carpeta.descripcion }}</span>
      </div>
      <div class="flex justify-end text-end gap-x-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
             stroke="currentColor"
             (click)="editarCarpeta(carpeta, $event)"
             class="w-6 h-6 acccion-conversacion lg:hidden">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
             stroke="currentColor"
             (click)="confirmarBorradoCarpeta(carpeta, $event)"
             class="w-6 h-6 acccion-conversacion lg:hidden text-red-500">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
        </svg>
      </div>
    </div>
    <div *ngIf="carpeta.abierta">
      <div class="mt-1">
        <small class="text-slate-400 select-none" *ngIf="carpeta.conversaciones && carpeta.conversaciones.length === 0">
          {{ 'No hay conversaciones en esta carpeta.' | translate }}
        </small>
      </div>
      <div *ngFor="let mes of carpeta.conversaciones" class="ml-2 mt-1">
        <small class="text-slate-400 select-none">{{ mes.mes | titlecase }}</small>
        <div *ngFor="let conversacion of mes.conversaciones">
          <div (click)="navegarChat(conversacion, $event)"
               draggable="true"
               (dragstart)="onDragStart($event, conversacion)"
               [ngClass]="{'bg-indigo-100': conversacionActiva(conversacion.id, carpeta.id)}"
               class="pl-0 justify-between hover:bg-indigo-100 mb-1 text-indigo-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold c-pointer conversacion">
            <div class="flex justify-between gap-x-2 ml-2"
                 title="{{conversacion.asunto}} ({{conversacion.fecha | date:'dd/MM/YYYY'}})">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"/>
              </svg>
              <span class="truncate max-w-menu select-none">{{ conversacion.asunto }}</span>
            </div>
            @if (!conversacion.dragOn) {
              <div class="flex justify-end text-end min-w-acciones gap-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor"
                     (click)="editarConversacion(conversacion, $event)"
                     class="w-6 h-6 acccion-conversacion lg:hidden">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor"
                     (click)="confirmarBorrado(conversacion, $event, carpeta)"
                     class="w-6 h-6 acccion-conversacion lg:hidden text-red-500">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                </svg>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</li>
<div class="min-h-52"
     (dragover)="onDragOver($event, undefined)"
     (dragleave)="onDragLeave($event, undefined)"
     (drop)="onDrop($event, undefined)">
  <hr class="h-0.5 border-t-0 bg-neutral-100 opacity-100  my-5"/>
  <p class="text-slate-500 text-sm select-none">{{ 'CONVERSACIONES' | translate }}</p>
  @if (mostrarPrevisualizacion && !esConversacionBloqueGeneral) {
    <li class="mt-3">
      <div
        class="border-2 border-indigo-500 border-dashed justify-between bg-indigo-50 mb-1 text-indigo-400 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold c-pointer conversacion">
        <div class="flex justify-between gap-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"/>
          </svg>
          <span class="truncate max-w-menu">{{ tituloConversacionAMover }}</span>
        </div>
      </div>
    </li>
  }
  @for (mes of conversacionesMes; track mes.mes) {
    <li class="mt-3">
      <small class="text-slate-400 ml-2">{{ mes.mes | titlecase }}</small>
      <div *ngFor="let conversacion of mes.conversaciones">
        <div (click)="navegarChat(conversacion, $event)"
             draggable="true"
             (dragstart)="onDragStart($event, conversacion)"
             [ngClass]="{'bg-indigo-100': conversacionActiva(conversacion.id, null)}"
             class="justify-between hover:bg-indigo-100 mb-1 text-indigo-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold c-pointer conversacion">
          <div class="flex justify-between gap-x-2"
               title="{{conversacion.asunto}} ({{conversacion.fecha | date:'dd/MM/YYYY'}})">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"/>
            </svg>
            <span class="truncate max-w-menu">{{ conversacion.asunto }}</span>
          </div>
          @if (!conversacion.dragOn) {
            <div class="flex justify-end text-end min-w-acciones gap-x-1">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor"
                   (click)="editarConversacion(conversacion, $event)"
                   class="w-6 h-6 acccion-conversacion lg:hidden">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor"
                   (click)="confirmarBorrado(conversacion, $event, null)"
                   class="w-6 h-6 acccion-conversacion lg:hidden text-red-500">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
              </svg>
            </div>
          }
        </div>
      </div>
    </li>
  } @empty {
    @if (!mostrarPrevisualizacion) {
      <div class="mt-1">
        <small class="text-slate-400 select-none">
          {{ 'No hay ninguna conversación' | translate }}
        </small>
      </div>
    }
  }
</div>


<app-modal-editar-carpeta #modalEditarCarpeta></app-modal-editar-carpeta>
<app-modal-confirmacion-accion #modalConfirmacionConversacion></app-modal-confirmacion-accion>
